const path = "subscriptions";

export default [
  {
    path: path,
    name: path,
    component: () => import("@views/offers/Index"),
  },
  {
    path: path + "/new",
    name: "subscription_new",
    component: () => import("@views/offers/subscription-list/subscription-create-update/Index"),
  },
  {
    path: path + "/" + "update" + "/:id",
    name: "subscription_update",
    component: () => import("@views/offers/subscription-list/subscription-create-update/Index"),
  },
];
