import Axios from "axios";
import Bus from "@/utils/bus";
import store from "@/plugins/store";
import router from "@/plugins/router";
import { fromIriReferenceToId } from "@/utils/form";
import config from "@api/doinsport/config";
import { httpClient, VUE_APP_BASE_URL } from "@api/doinsport/httpClient";

export let clubId = localStorage.getItem("selectedClub") ? fromIriReferenceToId("/clubs/", JSON.parse(localStorage.getItem("selectedClub"))) : "";

Bus.$on("on:user-connected", (iri) => {
  clubId = fromIriReferenceToId("/clubs/", iri);
});

export const _get = (URL, bufferLink = null, _config = null, handleError = false, api = VUE_APP_BASE_URL) => {

  return httpClient(api)
    .get(URL, _handleConfig(bufferLink, _handleTokenInit(_config)))
    .then(response => {
      return new Promise((resolve) => {
        resolve(response);
      });
    })
    .catch((error) => {
      return _handleErrors(error, handleError)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((exception) => {
          return Promise.reject(exception);
        });
    });
};

export const _post = (URL, data, _config = null, handleError = false, api = VUE_APP_BASE_URL) => {
  return httpClient(api)
    .post(URL, data, _handleTokenInit(_config))
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return _handleErrors(error, handleError)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((exception) => {
          return Promise.reject(exception);
        });
    });
};

export const _put = (URL, data, _config = null, handleError = false, api = VUE_APP_BASE_URL) => {
  return httpClient(api)
    .put(URL, data, _handleTokenInit(_config))
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return _handleErrors(error, handleError)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((exception) => {
          return Promise.reject(exception);
        });
    });
};

export const _delete = (URL, _config = null, handleError = false, api = VUE_APP_BASE_URL) => {
  return httpClient(api)
    .delete(URL, _handleTokenInit(_config))
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return _handleErrors(error, handleError)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((exception) => {
          return Promise.reject(exception);
        });
    });
};

const _handleErrors = (error, handleError = false) => {
  if (error.response.status === 400 || error.response.status === 422 || error.response.status === 403) {
    if (handleError === false) {
      Bus.$emit("on:400-errors-handled", error);
      return Promise.reject(error);
    }
    return Promise.reject(error);

  } else if (error.response.status !== 401 && error.response.status !== 400 && error.response.status !== 422) {
    return Promise.reject(error);
  }
  const reload = false;
  return _handleAuthorization(error, reload);

};

const _handleConfig = (bufferLink, _config = null) => {
  let headers = {};
  if (bufferLink !== null) {
    headers["Content-Type"] = config.pdf_contentType;
  } else {
    headers["Content-Type"] = config.json_contentType;
  }
  if (_config) {
    if (_config.headers) {
      headers = {
        ...headers,
        ..._config.headers,
      };
      delete _config.headers;
    }
  }

  const configs = bufferLink !== null ?
    {
      responseType: config.array_buffer,
      responseEncoding: config.binary_encode,
      headers,
      ..._config,
    } :
    {
      headers,
      ..._config,
    };
  return configs;
};

const _handleTokenInit = (_config) => {
  if (localStorage.getItem("token")) {
    const Authorization = "Bearer " + JSON.parse(localStorage.getItem("token"));
    if (_config) {
      if (_config.headers) {
        _config.headers = {
          ..._config.headers,
          Authorization,
        };
      } else {
        _config["headers"] = { Authorization };
      }
    } else {
      _config = { headers: { Authorization } };
    }
  }

  return _config;
};

const _handleAuthorization = (error, reload) => {
  return Axios
    .post(
      process.env.VUE_APP_BASE_URL + "jwt-token/refresh",
      { refresh_token: JSON.parse(localStorage.getItem("refresh_token")) },
    )
    .then(response => {
      localStorage.setItem("refresh_token", JSON.stringify(response.data.refresh_token));

      localStorage.setItem("token", JSON.stringify(response.data.token));

      Axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;

      httpClient().defaults.headers.Authorization = "Bearer " + response.data.token;

      const config = error.config;

      config.headers.Authorization = "Bearer " + response.data.token;

      return Axios.request(error.config)
        .then(response => {
          return Promise.resolve(response);
        }).catch((error) => {
          return Promise.reject(error);
        });
    })
    .catch((error) => {
      if (error.response.status === 401 && ! reload) {
        reload = true;
        store.dispatch("auth/logout");
        router.replace("/auth/login");
      } else {
        console.warn("Unresolved error => :", error);
        return Promise.reject(error);
      }
    });
};
