<template>
  <form class="login-right-form">
    <div class="wrap-img">
      <img class="login-left-logo" src="@/assets/images/doinsport/login.svg">
    </div>
    <div class="login-right-form-header">
      <h2>{{ $t('auth.login.title') }}</h2>
    </div>
    <div class="form-group username">
      <input
        v-model="username"
        :placeholder="$t('auth.login.username')"
        type="email"
      >
    </div>
    <div class="form-group password">
      <input
        v-model="password"
        :placeholder="$t('auth.login.password')"
        :type="showPassword ? 'text' : 'password'"
      >
      <i :class="!showPassword ? 'fa-eye-slash' : 'fa-eye'" class="fa" @click="showPassword = !showPassword" />
    </div>
    <div class="login-right-form-bottom">
      <label class="switch">
        <span class="text">{{ $t("auth.login.remember-me") }}</span>
        <input id="checkbox1" type="checkbox">
        <div class="slider round" />
      </label>
    </div>
    <p v-if="status === 'error'" class="text-center text-danger font-weight-bold mt-2">
      {{ $t('auth.login.login-failed') }}
    </p>
    <div class="form-group login-right-form-submit">
      <button
        type="button"
        @click="signIn"
      >
        {{ $t("auth.login.connect") }}
      </button>
      <router-link class="password-recovery" :to="{ path: '/auth/password-recovery' }">
        {{ $t('auth.login.reset-password') }}
      </router-link>
    </div>
  </form>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "Login",
  data () {
    return {
      username: "",
      password: "",
      showPassword: false,
    };
  },
  computed: {
    ...mapGetters({ status: "auth/authStatus" }),
    hostName () {
      return window.location.hostname;
    },

    logo () {
      const isMouratoglou = this.hostName === process.env.VUE_APP_MOURATOGLOU_HOSTNAME;

      return isMouratoglou ? { "doinsport-mouratoglou-background": true } : { "doinsport-background": true };
    },
  },
  created () {
    this.username = "";
    this.password = "";
  },
  mounted () {
    window.addEventListener("keypress", this.singInEnterOrigin, false);
  },
  beforeDestroy () {
    window.removeEventListener("keypress", this.singInEnterOrigin);
  },
  methods: {
    singInEnterOrigin (e) {
      if (e.key === "Enter") {
        this.signIn();
      }
    },
    signIn () {
      const username = this.username;
      const password = this.password;

      this.$store.commit("auth/setConnecting", true);

      this.$store.dispatch("auth/login", {
        username,
        password,
      })
        .then(() => {
          this.$router.replace({ path: "/planning" })
            .then(() => {
              this.$router.go(0);
            });
        })
        .finally(() => this.$store.commit("auth/setConnecting", false));
    },
  },
};
</script>
